a,
h4,
h1 span,
.btn.btn-default,
.bullet,
.top-left ul li:hover a,
.top-left ul li:hover,
.top-right ul li a:hover,
.navbar > .container .navbar-brand, 
.navbar > .container-fluid .navbar-brand,
.navbar-default .navbar-nav > .active > a, 
.navbar-default .navbar-nav > .active > a:focus, 
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > li:hover > a,
.navbar-brand,
.dropdown-menu > li > a:focus, 
.dropdown-menu > li > a:hover,
.dropdown-menu > .active > a, 
.dropdown-menu > .active > a:focus, 
.dropdown-menu > .active > a:hover,
.navbar-default .navbar-nav > .open > a, 
.navbar-default .navbar-nav > .open > a:focus, 
.navbar-default .navbar-nav > .open > a:hover,
.control,
.control:focus,
#subscribe .btn.btn-default,
#testimonial-carousel .fa.fa-quote-left,
.price-text,
.service-details ul li::before,
.gallery-overlay a,
.post-overlay .fa,
.post-meta a:hover,
.post-meta a:hover fa,
.category-list li .fa,
.archive-list li .fa,
.category-list li a:hover,
.archive-list li a:hover,
.breadcrumb li a:hover,
.meta > span a:hover,
.comment-list .list-inline a:hover,
.contact-list .fa,
.footer-top ul li a:hover,
.close:hover,
.chosen-container .chosen-results li.active-result.highlighted,
input[type="submit"].btn.btn-primary:hover,
input[type="submit"].btn.btn-primary:hover,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a, 
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus, 
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus, 
.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover{
	color:#89468B;
}
#color-switcher h2 a,
.btn.btn-primary,
.btn.btn-default:hover,
.title h1::before,
.line-block span::before,
.line-block span::after,
.social-media > li .fa:hover,
.navbar-default .navbar-nav > li > a::before,
.navbar-default .navbar-toggle .icon-bar,
.control:hover,
#counter::before,
.cd-headline.clip .cd-words-wrapper::after,
.single-progressbar .progress .progress-bar,
.gallery-overlay::before,
#filter-list li.active, 
#filter-list li.active:focus, 
#filter-list li.active:hover,
#filter-list li:hover,
.pagination > .active > a, 
.pagination > .active > a:focus, 
.pagination > .active > a:hover, 
.pagination > .active > span, 
.pagination > .active > span:focus, 
.pagination > .active > span:hover,
.pagination > li > a:focus, 
.pagination > li > a:hover, 
.pagination > li > span:focus, 
.pagination > li > span:hover,
.sidebar-item .input-group-addon,
.search-submit,
.tag-list li a:hover,
.post_bottom ul li a span,
.back-top i,
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover,
#subscribe::before,
.navbar-default .navbar-nav > li.reservation > a,
.btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary.focus[disabled], .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover{
	background:#89468B;
}


.btn.btn-default,
.btn.btn-default:hover,
.bullet,
.social-media > li .fa:hover,
.navbar-default .navbar-toggle,
.control,
.control:focus,
#testimonial-carousel .author img,
.single-progressbar .progress,
#therapists .therapists-list:hover,
.price-table:hover,
#filter-list li.active, 
#filter-list li.active:focus, 
#filter-list li.active:hover,
#filter-list li:hover,
.pagination > .active > a, 
.pagination > .active > a:focus, 
.pagination > .active > a:hover, 
.pagination > .active > span, 
.pagination > .active > span:focus, 
.pagination > .active > span:hover,
.pagination > li > a:focus, 
.pagination > li > a:hover, 
.pagination > li > span:focus, 
.pagination > li > span:hover,
.post:hover .post-bottom,
.sidebar-item .form-control:focus,
.sidebar-item .input-group-addon,
.category-list li a:hover,
.archive-list li a:hover,
.tag-list li a:hover,
blockquote,
.comment-info img,
.form-control:focus,
.chosen-container-active.chosen-with-drop .chosen-single,
.chosen-container .chosen-drop,
.modal .form-control:focus,
input[type="submit"].btn.btn-primary:hover,
.navbar-default .navbar-nav > li.reservation > a,
.btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary.focus[disabled], .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover{
	border-color:#89468B;
}

.navbar-default .dropdown-menu{
	border-top-color:#89468B;
}
#subscribe .btn.btn-default{
	border-left-color:#89468B;
}
.sidebar-item h3{
	border-bottom-color:#89468B;
}
